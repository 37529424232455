import { createStore ,applyMiddleware,compose, combineReducers} from "redux";

import thunk from 'redux-thunk'
import common from './reducers/index'
import extension from './reducers/extension'
import rootReducer from "./reducers/rootReducer";



const configureStore =  createStore(rootReducer,applyMiddleware(thunk));



export default configureStore;