import edjsHTML from 'editorjs-html';
import { parseMethods } from './components/dashboard/ExtensionEditor/EditorJsParserConfig';

export const getExtensionDetailsFromFile = async (file, callbackResult) => {
  console.log("Called detaisl from extensio");
  let result = {
    classname: null,
    packagename: null,
    description: null,
    permissions: null
  }


  //java parse regex
  const packageRegex = /package[^;]*/
  const classRegex = /(\n|\A)(?:public\s)?(class|interface|enum)\s([^\n\s]*)/
  const descriptionRegex = /description(?: [^,]+,)+/
  const designerCompoenentRegex = /@DesignerComponent[^/]+[^)]*/
  const permissionsRegex = /[^="]*android.permission[^\)"]*/

  const reader = new FileReader()

  reader.readAsText(file)


  reader.onload = async (e) => {
    const text = (e.target.result)
    //console.log(text)
    const designerString = text.match(designerCompoenentRegex)
    result.packagename = text.match(packageRegex)[0]
    result.classname = text.match(classRegex)[3]
    result.description = text.match(descriptionRegex)[0]

    const replaceText = text.match(permissionsRegex)[0].replaceAll("android.permission.", '')
    const replaceSpace = replaceText.replaceAll(" ", '')
    const replaceCharacters = replaceSpace.replaceAll("+", '')
    const permissionArray = replaceCharacters.includes(',') ? replaceCharacters.split(',') : replaceCharacters
    result.permissions = permissionArray
    callbackResult(result)
    // console.log(text.match(designerString[0].match(permissionsRegex)));
    //console.log(result.packagename);


  }



}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatK = (number)=>{
  return number>999?number/1000 +"k":number;
}

/**
 * Create blocks for editorjs from extension aix details
 * @param {*} param0 
 * @returns 
 */
export const createDocumentationBlocks = async ({ blockProperties, methods, properties, events, name }) => {
  const data = {
    "blocks": []
  }
  if (blockProperties.length !== 0) {
    const head = { "type": "header", "data": { "text": "Designer Properties", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } }
    const table = {
      "type": "table", "data": {
        "content":

          blockProperties.map(item => [`<b>${item.name}</b>`, `<code class=\"inline-code\">${item.type}</code>`, item.description || ""])


      }, "tunes": { "alignTune": { "alignment": "left" } }

    }
    data["blocks"].push(head)
    data["blocks"].push(table)
  }
  const heading = { "type": "header", "data": { "text": "Blocks", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } }
  data["blocks"].push(heading)

  if (methods.length !== 0) {


    methods.map(method => {
      data["blocks"].push({ "type": "header", "data": { "text": `<u  class=\"cdx-underline\" >${method.name}</u>`, "level": 3 }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method description
      data["blocks"].push({ "type": "paragraph", "data": { "text": method.description }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method block
      data["blocks"].push({ "type": "blockly", "data": { "isEditMode": true, "type": "method", "componentName": name, "blockName": method.name, "hasOutput": "returnType" in method, "params": method.params.length !== 0 ? method.params.map(param => param.name) : [], "blockHtml": "", "blocksDivId": "blockRendered0.8420326494991806" } })
      if ("returnType" in method) {
        data["blocks"].push({ "type": "paragraph", "data": { "text": `returnType : <code class=\"inline-code\">${method.returnType}</code>` }, "tunes": { "alignTune": { "alignment": "left" } } })
      }
      if (method.params.length !== 0)
        data["blocks"].push({
          "type": "table", "data": {
            "content":
              method.params.map(param => [`<b>${param.name}</b>`, `<code class=\"inline-code\">${param.type}</code>`, ""])

          }, "tunes": { "alignTune": { "alignment": "left" } }
        })

    })

  }

  if (properties.length !== 0) {



    properties.map(property => {
      data["blocks"].push({ "type": "header", "data": { "text": `<u  class=\"cdx-underline\" >${property.name}</u>`, "level": 3 }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method description
      data["blocks"].push({ "type": "paragraph", "data": { "text": property.description }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method block
      data["blocks"].push({ "type": "blockly", "data": { "isEditMode": true, "type": "property", "componentName": name, "blockName": property.name, "hasOutput": "returnType" in property, "params": "params" in property && property.params.map(param => param.name), "blockHtml": "", "blocksDivId": "blockRendered0.8420326494991806" } })
      //return type paragraph
      if ("returnType" in property) {
        data["blocks"].push({ "type": "paragraph", "data": { "text": `returnType : <code class=\"inline-code\">${property.returnType}</code>` }, "tunes": { "alignTune": { "alignment": "left" } } })
      }


    })

  }

  if (events.length !== 0) {

    events.map(event => {
      data["blocks"].push({ "type": "header", "data": { "text": `<u  class=\"cdx-underline\" >${event.name}</u>`, "level": 3 }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method description
      data["blocks"].push({ "type": "paragraph", "data": { "text": event.description }, "tunes": { "alignTune": { "alignment": "left" } } })
      //method block
      data["blocks"].push({ "type": "blockly", "data": { "isEditMode": true, "type": "event", "componentName": name, "blockName": event.name, "hasOutput": "returnType" in event, "params": event.params.length !== 0 && event.params.map(param => param.name), "blockHtml": "", "blocksDivId": "blockRendered0.8420326494991806" } })
      //return type paragraph
      if ("returnType" in event) {
        data["blocks"].push({ "type": "paragraph", "data": { "text": `returnType : <code class=\"inline-code\">${event.returnType}</code>` }, "tunes": { "alignTune": { "alignment": "left" } } })
      }
      if (event.params.length !== 0)
        data["blocks"].push({
          "type": "table", "data": {
            "content":
              event.params.map(param => [`<b>${param.name}</b>`, `<code class=\"inline-code\">${param.type}</code>`, ""])

          }, "tunes": { "alignTune": { "alignment": "left" } }
        })

    })

  }

  return data
}

/**
 * 
 * @param {Object} blocks 
 * @returns {html , renderBlockIds}
 */
export async function createDocsHtmlFromBlocks(blocks) {
  if (!blocks || !blocks.blocks || blocks.blocks.length === 0) {
    return null
  }
  let headers = []
  let renderBlockIds = []
  blocks.blocks.map((singleBlock) => {
    if (singleBlock.type === "header") {

      headers.push(singleBlock)
    } if (singleBlock.type === "blockly") {
      renderBlockIds.push({
        id: singleBlock.data.blocksDivId,
        data: singleBlock.data
      }
      )

    }
  })
  //setToc(headers)

  const html = await edjsHTML(parseMethods).parse(blocks)


  return {
    html: html.join(""),
    renderBlockIds: renderBlockIds
  }

 
}

export const renderEditorJsBlocks =  async function (blocks){
  if(!blocks) return ""
  console.log(blocks);
  return await edjsHTML(parseMethods).parse(blocks).join("")
}

export const localStorageUser = JSON.parse(localStorage.getItem("profile"));

export const getLoggedInUserToken = localStorageUser && localStorageUser.token;

export const getLoggedInUserId = localStorageUser && localStorageUser.id;