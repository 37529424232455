import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
//import Home from './components/Home';
//import Dashboard from './components/dashboard/main'
import { useDispatch, useSelector } from "react-redux";
import { localSigning } from "./redux/actions/index";
import PrivateRoute from "./PrivateRoute";
import { extensionData, getUser } from "./api/index";
import "animate.css/animate.css";
import "./css/bulma-badge.min.css";
import "./css/bulma-pageloader.min.css";
import "./css/mystyles.css";
import "./App.css";

import "./css/plugin.css";

import LoadingScreen from "./components/LoadingScreen";

//import 'firebase/auth';
//import firebase from 'firebase/app';
import { FIREBASE_CONFIG } from "./config";
import PasswordReset from "./components/Auth/PasswordReset";
import { checkAuthTokenExpiry } from "./actions/auth";
import loadable from "@loadable/component";

import firebase from "firebase/app";
import "firebase/auth";

import NavHeader from "./components/NavHeader";
import { localStorageUser } from "./utils";

//const NavHeader = React.lazy(() => import('./components/NavHeader'));
const Home = React.lazy(() => import("./components/Home"));
const Dashboard = React.lazy(() => import("./components/dashboard/main"));
const ExtensionDetail = React.lazy(() =>
  import("./pages/ExtensionDetail/index")
);
const SubscribePage = React.lazy(() => import("./pages/BuyGold"));
//const firebaseauth = loadable.lib(() => import('firebase/auth'))
//const firebase = loadable.lib(() => import( 'firebase/app'))

if (!firebase.apps.length) {

  firebase.initializeApp(FIREBASE_CONFIG);

} else {
  firebase.app(); // if already initialized, use that one
}


function App() {
  const dispatch = useDispatch();

  //Checks for user logged in already from local storage
  useEffect(() => {

    if (localStorageUser) {
     
      console.log("Localstorage user exists", localStorageUser,firebase.app().auth().currentUser );
      firebase.auth().onAuthStateChanged(firebaseUser=>{
        if (firebaseUser) {
          dispatch(checkAuthTokenExpiry(firebaseUser.emailVerified));
        }
      }) 
    
    } else {
      dispatch(localSigning(false));
    }
  }, []);



  const isSignedInLocal = useSelector((state) => state.common.isSignedInLocal);

  return (
    <BrowserRouter>
      <LoadingScreen />

      <NavHeader  />

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            path={"/password-reset/action"}
            component={PasswordReset}
            exact
          />
          <Route component={SubscribePage} path="/gold" exact />

          <Route component={Home} path="/" exact />

          <Route
            component={ExtensionDetail}
            path={"/extension/:extensionSlug"}
            exact
          />
          <Route
            path={isSignedInLocal ? "/dashboard" : "/"}
            component={isSignedInLocal ? Dashboard : Home}
          />


          {/*<Route path={"/dashboard"} component={Dashboard } />*/}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
