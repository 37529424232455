import React, { useState } from 'react';
import { SignupLoginTabs } from './Signup'
import { useDispatch, useSelector } from 'react-redux'
import { signup, signin } from '../actions/auth'
import { firebaseEmailPasswordAuth } from './Auth/SocialLogin';



function Modal(props) {

  const tabState = useSelector(state => state.common.tabState);
  const authArray = useSelector(state => state.common.authArray);
  const dispatch = useDispatch();
  
  const [isSubmitting, setIsSubmitting] = useState(false)

  function closeModal() {
    console.log('Modal closing')
    props.onClosed('')
  }

  function onSubmit(e) {
    e.preventDefault();
    console.log(authArray)
    //setIsSubmitting(true)
    if (tabState === 0) {//is signup
    // dispatch(signup(authArray))
    dispatch(firebaseEmailPasswordAuth(authArray, true))
    } else {
      //dispatch(signin(authArray))
      
      dispatch(firebaseEmailPasswordAuth(authArray, false))
    }

  }

  return (
    <div className={"modal " + props.modalNow}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <div className="modal-card-title">

            <SignupLoginTabs />

          </div>
          <button className="delete" aria-label="close" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body ">
          {props.children}
        </section>
        <footer className="modal-card-foot ">

          <div className="field is-grouped ">
            <div className="control">
              <button className={"button is-link " + (isSubmitting?" is-loading " : "") } onClick={onSubmit} >Submit</button>
            </div>
            <div className="control">
              <button className="button is-link is-light" onClick={closeModal}>Cancel</button>
            </div>
          </div>

        </footer>
      </div>
    </div>
  );
}

export default Modal;