import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { signupTab } from '../redux/actions/index'
import { authArrayAction } from '../redux/actions/authAction'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faExclamationTriangle, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import LoginGithub from 'react-login-github';
import { getGithubToekn } from '../api';
import SocialLogin, { firebaseAuthSendPasswordResetEmail } from './Auth/SocialLogin';
import FieldBox from './dashboard/FieldBox';
import { Link } from 'react-router-dom';

// Firebase App (the core Firebase SDK) is always required and must be listed first


export function Login(props) {

  const authArray = useSelector(state => state.common.authArray);
  const dispatch = useDispatch();

  var signInValues = authArray

  function storeToRedux() {
    dispatch(authArrayAction(signInValues))//dispatch used for calling function
  }

  function handleInputChange(e){
    console.log(signInValues);
    var name = e.target.name
    var value =e.target.value
    signInValues[name]=value
    storeToRedux()
  }

 

  return (
    <div>


      <div className="field">
      <label className="label level-left">Email :</label>
        <div className="control has-icons-left has-icons-right">
          <input className="input" name="email" type="email" placeholder="Email" onChange={handleInputChange}></input>
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>

        </div>
      </div>
      <div className="field">
      <label className="label level-left">Password :</label>
        <p className="control has-icons-left">
          <input className="input" name="password" type="password" placeholder="Password" onChange={handleInputChange}></input>
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </p>
      </div>
    
    </div>
  );
}


export function SignupLoginTabs() {

  const tabState = useSelector(state => state.common.tabState);
  const dispatch = useDispatch();

  function handleTabChange() {
    dispatch(signupTab(tabState === 0 ? 1 : 0))//dispatch used for calling function

  }


  return (

    <div className="tabs is-toggle is-toggle-rounded is-centered">
      <ul>
        <li className={tabState === 0 ? 'is-active' : ''} onClick={handleTabChange}>
          <a>
            <span>Signup</span>
          </a>
        </li>
        <li className={tabState === 0 ? '' : 'is-active'} onClick={handleTabChange}>
          <a>
            <span>Login</span>
          </a>
        </li>
      </ul>
    </div>

  )
}



function Signup(props) {

  const tabState = useSelector(state => state.common.tabState);

  const authArray = useSelector(state => state.common.authArray);
  const dispatch = useDispatch();

  var signupValues = authArray

  function storeToRedux() {
    dispatch(authArrayAction(signupValues))//dispatch used for calling function
  }

  function handleUsernameChange(e) {
    var value = e.target.value
    if (value.match(/^[a-zA-Z0-9]{4,}$/)) {

      setUserNameError(false)
      setUserNameAvailable(true)
      signupValues.username = value
      storeToRedux()
      
    } else {
      setUserNameError(true)
      setUserNameAvailable(false)
    }
    
  }


  function handleEmailChange(e) {
    var value = e.target.value;
    
    if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      setEmailError(false)
      signupValues.email = value
      storeToRedux()
    } else {
      setEmailError(true)
    }
  }

  function handleInputChange(e){
    var name = e.target.name
    var value =e.target.value
    signupValues[name]=value
    storeToRedux()
  }

  function handleSendPasswordResetEmail(){
    const val = firebaseAuthSendPasswordResetEmail(authArray.email)
   
  }

  const [usernameAvailable, setUserNameAvailable] = useState(false)
  const [usernameError, setUserNameError] = useState(true)
  const [emailError, setEmailError] = useState(true)

  return (
    <div className="columns is-centered is-vcentered">

      <div className="column is-centered ">
        <div>

          <div className={(tabState === 0 ? 'is-active' : 'is-hidden')} id="Pictures">

            <div className="field ">
              <label className="label level-left">Name :</label>
              <div className="control">
                <input className="input" type="text" name="name" placeholder="Full Name" onChange={handleInputChange}></input>
              </div>
            </div>

            <div className="field ">
              <label className="label level-left">Username :</label>
              <div className={"control has-icons-left " + (usernameAvailable ? 'has-icons-right' : 'is-loading')}>
                <input name="username" className="input" type="text" placeholder="Username" onChange={handleUsernameChange}></input>
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span className={usernameAvailable ? "icon is-small is-right has-text-success" : 'is-hidden'}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>



              </div>
              <p className={"help is-danger level-left " + (usernameError ? '' : 'is-hidden')}>Username must be atleast 4 letters and
              should not contain spaces and symbols</p>
            </div>

            <div className="field">
              <label className="label level-left">Email :</label>
              <div className="control has-icons-left has-icons-right">
                <input className="input" name='email' type="email" placeholder="Email" onChange={handleEmailChange}></input>

                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <span className={emailError ? "icon is-small is-right has-text-danger" : 'is-hidden'} >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
                <span className={emailError ? "is-hidden" : 'icon is-small is-right has-text-success'}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>


              </div>
              <p className="help is-danger is-hidden">This email is invalid</p>
            </div>

            <div className="field">
              <label className="label level-left">Password :</label>
              <p className="control has-icons-left">
                <input className="input" name="password" type="password" placeholder="Password" onChange={handleInputChange}></input>
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </p>
            </div>

            <div className="field level-left">
              <div className="control">
                <label className="checkbox mr-2">
                  <input name='agreement' type="checkbox" onChange={handleInputChange}></input>
                  <span className="m-2">
                    I agree to the <a href="#">terms and conditions</a>
                  </span>
                </label>
              </div>
            </div>

          </div>

          <div className={tabState === 0 ? 'is-hidden' : 'is-active'} id="Music">
            <Login />

          </div>
         
          
          
        </div>
        <a className="level-right" onClick={handleSendPasswordResetEmail} >Forgot password?</a>
      <br></br>
          <strong  className="level-item">OR</strong>
          <SocialLogin/>
      </div>
      
    </div>
  );
}


export default (Signup);