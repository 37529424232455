import {common} from './index'
import {extension} from './extension'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    common,
    extension,
  
});

export default rootReducer;