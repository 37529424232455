import React from 'react';
import { useSelector } from 'react-redux';

function LoadingScreen(props) {

    const isLoading = useSelector(state => state.isLoading);
  const isLoadingError = useSelector(state=> state.isLoadingError)
  console.log(isLoadingError);
  console.log(isLoading);

    return (
        <div className={"pageloader " + (isLoading  +" "+ isLoadingError )}>
            <span className="title ">{isLoadingError ==='is-warning'? "Internet Error":"Loading..."}</span>
            </div>
    );
}

export default LoadingScreen;