import { toast } from 'bulma-toast';

import React from 'react';

const Toast = function ({message , theme }) {
  
    return (
        
          toast({
    message: message,
    duration: 3000,
    dismissible: true,
    type: theme || "is-warning",
    position: 'top-right',
    closeOnClick: true,
    pauseOnHover: true,
    opacity: 0.8,
    animate: { in: 'fadeIn', out: 'fadeOut' }
  })  
       
    );
}


export default Toast;
