
import React, { useEffect } from 'react';
// Add the Firebase products that you want to use
import loadable from '@loadable/component'
import 'firebase/auth';
import firebase from 'firebase/app';

import { FacebookLoginButton, GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { FIREBASE_CONFIG } from '../../config';
import { useDispatch } from 'react-redux';
import { checkAuthTokenExpiry, doSocialLogin, signin, signup, updateUserHook } from '../../actions/auth';
import Toast from '../bulma-components/Toast';
import { toast } from 'bulma-toast';
import { SENT_VERIFICATION_EMAIL } from '../../constants/messages';
import { DOMAIN } from '../../constants/common';

//const firebaseauth = loadable.lib(() => import('firebase/auth'))
//const firebase = loadable.lib(() => import('firebase/app').then(console.log("Firebase imported")))
export const signOutFirebase = function () {
  firebase.auth().signOut().then((result) => console.log(result)).catch((error) => console.log(error))

}
//send password reset email
export const firebaseAuthSendPasswordResetEmail = async function (email) {
  console.log(email);
  firebase.auth().sendPasswordResetEmail(email).then(() => {
    Toast({ message: "Password reset mail has been sent to email " + email })
  }).catch((error) => {
    console.log("Email send error", error);
    Toast({ message: error.message })

  })
}

//Verify the code and confirms it
export const verifyAndConfirmPasswordReset = function (email, newPassword, code, continueUrl) {
  // Verify the password reset code is valid.
  firebase.auth().verifyPasswordResetCode(code).then((email) => {
    var accountEmail = email;

    firebase.auth().confirmPasswordReset(code, newPassword).then((resp) => {
      Toast({ message: "Password reset successfull" })
    }).catch((error) => {
      const errorMessage = error.message
      console.log(error);

      Toast({ message: errorMessage })
    });

  }).catch((error) => {
    const errorMessage = error.message
    console.log(error);
    Toast({ message: errorMessage })

  });
}

//Called when login is clicked and if email not verified
export const sendVerificationEmail = () => {
  const authActionCode = {
    url: DOMAIN
  }
  firebase.auth().currentUser.sendEmailVerification(authActionCode).then((result) => {
    console.log(result);
    Toast({ message: SENT_VERIFICATION_EMAIL })
  }).catch((error) => {
    console.log(error);
    Toast({ message: error.message })
  })
}

//Custom verify email with custom component
export const firebaseVerifyEmail = async (code, continueUrl) => {
  return firebase.auth().applyActionCode(code).then((result) => {
    console.log(result);
    return result

  })
}

//Firebase email, password authentication
export const firebaseEmailPasswordAuth = (authArray, isSignUp) => async (dispatch) => {


  if (isSignUp) {//create new account
    console.log(authArray);
    console.log("Creating account");
    firebase.auth().createUserWithEmailAndPassword(authArray.email, authArray.password)
      .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        console.log(user);
        authArray.emailVerified = user.emailVerified
        dispatch(signup(authArray,user.emailVerified))
        //After user created verification email is sent

        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(error);
        Toast({ message: errorMessage })
        // ..
      });
  } else {
    firebase.auth().signInWithEmailAndPassword(authArray.email, authArray.password)
      .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        console.log(user);
       
        dispatch(signin(authArray, user.emailVerified))
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
        console.log(error);
      });
  }

}

function SocialLogin(props) {
  /*firebase.load().then((res)=> {
console.log(res);
  })*/
  const githubProvider = new firebase.auth.GithubAuthProvider()
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const googleProvider = new firebase.auth.GoogleAuthProvider();


  const dispatch = useDispatch()

  useEffect(() => {
    console.log("called once");
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log(user);
        console.log(user.getIdToken());
        //Check token expired or not from server
      
      } else {
        // No user is signed in.
        console.log("no user");
      }
    });
  }, [])

  //All social login response are controlled here
  function handleLoginResponse(isSuccess, result, provider) {
    if (isSuccess) {//if login succesfull
      var user = result.user;
      console.log(result);
      const username = (result.additionalUserInfo).username
      const { email, displayName, photoURL, emailVerified } = user
      const data = {
        email: email,
        name: displayName,
        avatarUrl: photoURL,
        username: username || email.match(/^([^@]*)@/)[1],
        emailVerified: emailVerified
      }

      dispatch(doSocialLogin(data,emailVerified))
    } else {//if error occured
      var errorCode = result.code;
      if (errorCode === 'auth/account-exists-with-different-credential') {
        console.log(result);
        let usedAuth = ''
        firebase.auth().fetchSignInMethodsForEmail(result.email)
          .then(async (providers) => {
            usedAuth = providers[0]

            Toast({ message: "Account exist with other  '" + usedAuth + "' sign in method" })

          })

        /*const existingEmail = result.email;
        const pendingCred = result.credential;
        // Lookup existing account’s provider ID.
         return firebase.auth().fetchSignInMethodsForEmail(result.email)
          .then( async (providers) =>{
            console.log(providers);
            let prevProvider
            if (provider[0] === "google.com") {
               prevProvider =  new firebase.auth.GoogleAuthProvider();
            }
            console.log(prevProvider);
              //var googProvider = new firebase.auth.GoogleAuthProvider();
              // Sign in user to Google with same account.
              //provider.setCustomParameters({ 'login_hint': existingEmail });
              return await firebase.auth().signInWithPopup(googleProvider).then(function (result) {
                return result.user;
              });
            
          })
          .then(function (user) {
            // Existing email/password or Google user signed in.
            // Link Facebook OAuth credential to existing account.
            console.log(user);
            
            return user.linkWithCredential(pendingCred);
          });*/



      }
    }

  }

  function handleGithubLogin(e) {
    /* console.log(e);
     if (e) {
       const data ={
         code:e.code
       }
       getGithubToekn(data)
     }*/
    firebase
      .auth()
      .signInWithPopup(githubProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        var token = credential.accessToken;

        // The signed-in user info.
        handleLoginResponse(true, result, githubProvider)
        // ...
      }).catch((error) => {
        console.log(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        handleLoginResponse(false, error, githubProvider)
        // ...
      });

  }

  function handleFacebookLogin(e) {

    firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        var token = credential.accessToken;

        // The signed-in user info.
        var user = result.user;
        console.log(result);
        // ...
      }).catch((error) => {
        console.log(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });

  }

  function handleGoogleLogin(e) {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        var token = credential.accessToken;

        // The signed-in user info.
        handleLoginResponse(true, result, googleProvider)
        // ...
      }).catch((error) => {
        console.log(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        handleLoginResponse(false, error, googleProvider)
        // ...
      });
  }

  return (
    <div class="level">

      <GithubLoginButton className="button" style={{ fontSize: "initial" }} onClick={handleGithubLogin} />
      <FacebookLoginButton className="button" style={{ fontSize: "initial" }} onClick={handleFacebookLogin} />
      <GoogleLoginButton className="button" style={{ fontSize: "initial" }} onClick={handleGoogleLogin} />

    </div>
  );

}



export default SocialLogin;