
import { getUser, signIn, signUp, socialLogin, updateUser, uploadToCloudinary } from '../api/index';
import { sendVerificationEmail, signOutFirebase } from '../components/Auth/SocialLogin';
import Toast from '../components/bulma-components/Toast';
import { SUCCESSFULL } from '../constants/messages';
import { setCloudinaryImageUploaded } from '../redux/actions/extensionDetails';
import { localSigning } from '../redux/actions/index';
import { setUser } from '../redux/actions/user';


export const signin = (formData, emailVerified, history) => async (dispatch) => {
  try {

    const { data } = await signIn(formData);

    const { email, id, token } = data

    localStorage.setItem('profile', JSON.stringify({ email, id, token }))

    Toast({ message: SUCCESSFULL, theme: 'is-success' })
    if (!emailVerified) {

      sendVerificationEmail()
    } else {
      dispatch(localSigning(true))
      dispatch(setUser(data.user))
    }
    //history.push('/')
  } catch (error) {
    if (error.response.data.message) {
      console.log(error.response.data.message);
    }
    console.log(error);
    console.log(error.response);
  }
};

//
export const signup = (formData, emailVerified) => async (dispatch) => {
  try {
    const { data } = await signUp(formData);
    console.log(data);
    //const isSigned = true
    //localStorage.setItem('profile', JSON.stringify(data))
    //dispatch(localSigning(isSigned))
    Toast({ message: SUCCESSFULL, theme: 'is-success' })
    if (!emailVerified) {
      sendVerificationEmail()//After account created verification email is send to the user
    }

    //history.push('/')
  } catch (error) {
    console.log(error);
    console.log(error.response);
    Toast({ message: error.message, theme: 'is-danger' })
  }
};

//Social login only need singin
export const doSocialLogin = (formData, emailVerified) => async (dispatch) => {
  try {
    console.log("Social login data", formData);
    const { data } = await socialLogin(formData);
    console.log("Social login returned", data);

    const { email, id, token } = data

    localStorage.setItem('profile', JSON.stringify({ email, id, token }))

    if (emailVerified && emailVerified === true) {
      dispatch(localSigning(true))
      dispatch(setUser(data.user))
    } else {
      sendVerificationEmail()
    }

    //history.push('/')
  } catch (error) {
    console.log("Social login", error);
    console.log(error.response);
  }
};

export const updateUserHook = (emailAndData) => async (dispatch) => {
  console.log("Updating user");
  try {
    const { data } = await updateUser(emailAndData);
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export const checkAuthTokenExpiry = (emailVerified) => async (dispatch) => {
  //const { data, err } = useSWR(getLoggedInUserId, getUser)
  try {

   
    const { data } = await getUser();//curly bracket varaible to parse json from result
    console.log("Token verified", data);

    //send email if not verified
    if (emailVerified && emailVerified === true) {
      dispatch(localSigning(true))
      dispatch(setUser(data.user))
    } else {
      sendVerificationEmail()
    }
    //history.push('/')
  } catch (error) {
    console.log(error.response);
    localStorage.removeItem('profile')
    dispatch(localSigning(false))//set to logged out if token expired
  }
};

export const signOut = () => async (dispatch) => {
  try {
    console.log("Removing user");
    localStorage.removeItem('profile')
    signOutFirebase()
    dispatch(localSigning(false))//set to logged out 
  } catch (error) {
    console.log(error.response);
    //localStorage.removeItem('profile')
    //dispatch(localSigning(false))//set to logged out if token expired
  }
};

export const uploadToCloudinaryAsync = (formData) => async (dispatch) => {
  try {
    const { data } = await uploadToCloudinary(formData);
    dispatch(setCloudinaryImageUploaded(data))
    //return data imageCloudinaryData
    //history.push('/')
  } catch (error) {
    console.log(error.message);
  }
};


/*export async function signup(formData) {
  try {
    const { data } = await signUp(formData);

    localStorage.setItem('profile', JSON.stringify(data))
    //history.push('/')

  } catch (error) {
    console.log(error);
  }
}*/