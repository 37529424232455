//change value for respected action

import { NEWUSER, NEW_EXTENSION, PERMISSIONTAGS } from "../../constants/actionTypes";

const intialStates = {
  authArray: {
    name: '',
    username: '',
    email: '',
    password: '',
    emailVerified: false
  },
  tabState: 1,
  extensionTags: [],
  permissionTags: [],
  reduxUser: {
    profile: {
      website: "",
      location: ""
    }
  },
  extensionsJson: { "ext": [] },
  isLoading: '',
  isLoadingError: 'is-primary',


}
export function common(state = intialStates, action) {
  switch (action.type) {
    case "SIGNUP_TAB":
      return {
        ...state,
        tabState: action.payload
      };
    case "AUTH":
      return {
        ...state,
        authArray: action.payload
      };
    case "LOCAL_STORAGE":

      return {
        ...state,
        isSignedInLocal: action.payload
      };
    case "IS-FREE":

      return {
        ...state,
        isFree: action.payload
      };

    case "ALL_TAGS":

      return {
        ...state,
        extensionTags: action.payload
      };

    case PERMISSIONTAGS:

      return {
        ...state,
        permissionTags: action.payload
      };
    case "IMAGE_CLOUDINARY":
      return {
        ...state,
        imageCloudinaryData: action.payload
      }

    case NEWUSER:
      return {
        ...state,
        reduxUser: action.payload
      };

    case 'EXTENSIONS':
      return {
        ...state,
        extensionsJson: action.payload
      };

    case 'LOADING':
      return {
        ...state,
        isLoading: action.payload
      };

    case 'NET_ERROR':
      return {
        ...state,
        isLoadingError: action.payload
      };

    default:
      return state;
  }
};