//put the value for respectedd action
//action is called in component

import { AIX_VERSIONS, NEW_EXTENSION_MAJOR_DETAILS, NEW_EXTENSION_MINI_DETAILS, PERMISSIONTAGS } from "../../constants/actionTypes"

//details of extension when new one is created
export function setIsFree(value) {
    return {
       type: 'IS-FREE',

       payload: value
    }
 }

 export function setExtensionTags(value) {
   return {
      type: 'ALL_TAGS',

      payload: value
   }
}

export function setPermissionsTags(value) {
   return {
      type: PERMISSIONTAGS,

      payload: value
   }
}

export function setCloudinaryImageUploaded(value) {
   return {
      type: 'IMAGE_CLOUDINARY',

      payload: value
   }
}

export function setExtensionsJson(value) {
   return {
      type: 'EXTENSIONS',

      payload: value
   }
}

export function setAixVersions(value) {
      return {
         type: AIX_VERSIONS,
   
         payload: value
      }
   }

export function setExtensionMiniDetails(value) {
      return {
         type: NEW_EXTENSION_MINI_DETAILS,
   
         payload: value
      }
   }

   export function setExtensionMajorDetails(value) {
      return {
         type: NEW_EXTENSION_MAJOR_DETAILS,
   
         payload: value
      }
   }

   export function setExtensionDocsEditor(value) {
      return {
         type: "EXTENSION_DOCUMENTATION_EDITOR",
   
         payload: value
      }
   }
