import { renderToString } from "react-dom/server";
import AttachesTool from '@editorjs/attaches'

export const blockConfigurations = {
    "code": {
        tag: `code`,
        class: "code-inline"
    },
    "delimiter": {
        tag: `hr`,
        class: ""
    },
    "table": {
        tableClass: "tc-table__wrap tc-table mb-1",
        tdClass: "tc-table__cell tc-table__area ",
    },
    "checklist": {
        checkListItemClass: " cdx-checklist__item ",
        checkClass: " cdx-checklist__item-checkbox ",
        checkBoxTextClass: " cdx-checklist__item-text ",
        checkBoxCheckedClass: " cdx-checklist__item--checked ",
        checkListTag: "div",
        checkBoxTag: "span",
        checkboxTextTag: "p",
    },
    "header": {
        headerClass: " ce-header "
    },
    "paragraph": {
        paragraphClass: " ce-paragraph mb-1 mt-1 "
    },
    "messageBox": {
        classnames: {
            messageContainer: " message m-2",
            messageHeader: " message-header ",
            messageBody: " message-body ",
            success: " is-success ",
            warning: " is-warning ",
            info: " is-info ",
            danger: " is-danger ",
        },
    },
    "button": {
        classnames: {
            button: " btn button ",
            defaultTheme: " is-info ",
        }
    },
    "attaches": {
        classnames: {
            container: " cdx-attaches--with-file m-2 ",
            fileIcon: " cdx-attaches__file-icon ",
            titleContainer: " cdx-attaches__file-info ",
            title: " cdx-attaches__title ",
            downloadIcon: " cdx-attaches__download-button ",

        },
        icons: {
            downloadArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17"><path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path></svg>`,
            fileIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
            <path d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"/>
          </svg>`
        },
        EXTENSIONICONCOLORS: {
            doc: '#3e74da', docx: '#3e74da', odt: '#3e74da', pdf: '#d47373', rtf: '#656ecd', tex: '#5a5a5b', txt: '#5a5a5b',
            pptx: '#e07066', ppt: '#e07066', mp3: '#eab456', mp4: '#f676a6', xls: '#3f9e64', html: '#2988f0', htm: '#2988f0', png: '#f676a6', jpg: '#f67676',
            jpeg: '#f67676', gif: '#f6af76', zip: '#4f566f', rar: '#4f566f', exe: '#e26f6f', svg: '#bf5252', key: '#e07066', sketch: '#df821c', ai: '#df821c', psd: '#388ae5', dmg: '#e26f6f', json: '#2988f0', csv: '#3f9e64'

        }
    },


}


function codeBlockParser(block) {
    const configs = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? "ce-tune-alignment--" + block.tunes?.alignTune?.alignment + '"' : ''
    const classnames = configs.class + alignmentClass
    return `<${configs.tag} class=${classnames}> ${block.data.code} </${configs.tag}>`;
}

function delimiterParser(block) {
    const configs = blockConfigurations[block.type]
    return `<${configs.tag} /> `;
}


function tableParser(block) {
    const configs = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? "ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''

    const rows = block.data.content.map((row) => {
        return `<tr>${row.map((cell) =>
            `<td class="${configs.tdClass + alignmentClass}">${cell}</td>`, " "

        ).join("")}</tr>`
    })
    return `<div ><table class="${configs.tableClass}"><tbody>${rows.join("")}</tbody></table></div>`
}

function paragraphParser(block) {
    const blockConfig = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? "ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''
    const classnames = blockConfig.paragraphClass + alignmentClass
    return `<p class="${classnames}"> ${block.data.text} </p>`
}

function headerParser(block) {
    const blockConfig = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? " ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''
    const classnames = blockConfig.headerClass + alignmentClass
    const text = block.data.text.replace(/\s+/g, '-').toLowerCase();
    return `<h${block.data.level} class="${classnames}" id="${block.id}">${block.data.text}</h${block.data.level}>`
}


function checklistParser(block) {
    const blockConfig = blockConfigurations[block.type]
    const checkListItems = block.data.items
    const alignmentClass = block.tunes?.alignTune?.alignment ? " ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''

    const checkBoxes = checkListItems.map((item) => {
        return `<div class="${blockConfig.checkListItemClass + (item.checked ? blockConfig.checkBoxCheckedClass : '')}">
            <span class="${blockConfig.checkClass}"></span>
            <p class="${blockConfig.checkBoxTextClass}">${item.text}</p>
        </div>`
    })
    return ` <div class="${alignmentClass}">${checkBoxes.join("")}</div>`;
}

function buttonParser(block) {
    const blockConfig = blockConfigurations[block.type]//returns class names
    const alignmentClass = block.tunes?.alignTune?.alignment ? " ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''
    const buttonHtml = `<div class="anyButtonContainer__anyButtonHolder m-2"><a class="${blockConfig.classnames.button +
        blockConfig.classnames.defaultTheme + alignmentClass}" target="_blank"
         rel="nofollow noindex noreferrer" href="${block.data.link}">${block.data.text}</a></div>`
    return buttonHtml
}

function messageBoxParser(block) {
    const blockConfig = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? " ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''
    const theme = block.data?.theme && blockConfig.classnames[block.data?.theme]
    const containerClass = blockConfig.classnames.messageContainer + alignmentClass + theme

    const html = `<article class="${containerClass}">
    <div class="${blockConfig.classnames.messageHeader}">
    <p>${block.data.messageHeader}</p>
    </div>
    <div class="${blockConfig.classnames.messageBody}">
    ${block.data.messageBody}
    </div>
    </article>`
    return html
}

function attachesParser(block) {
    if (!block?.data?.file?.url) {
        console.log("no attachemnt found", block?.data?.file?.url);
        return
    }

    const blockConfig = blockConfigurations[block.type]
    const alignmentClass = block.tunes?.alignTune?.alignment ? " ce-tune-alignment--" + block.tunes?.alignTune?.alignment : ''
    const data = block.data
    const containerClass = blockConfig.classnames.container + alignmentClass
    const fileIconColor = blockConfig.EXTENSIONICONCOLORS[data?.file?.extension]

    const html = `<div class="${blockConfig.classnames.fileIcon}" style="color: ${fileIconColor};"  data-extension=${data?.file?.extension} >${blockConfig.icons.fileIcon}</div>
    <div class="${blockConfig.classnames.titleContainer}" > <p class="${blockConfig.classnames.title}">${data.file.name}</p> </div>
    <a class="${blockConfig.classnames.downloadIcon}" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer">${blockConfig.icons.downloadArrowIcon}</a>`
    return `<div class="${containerClass}" >${html}</div>`

}


//parse blockly blocks data block and convert to html
function blocklyParser(block){
    const type = block.type
    const data = block.data

    return `<div id="${data.blocksDivId}" > </div>`
}

export const parseMethods = {
//key is the block key and value is its parser function
    code: codeBlockParser,
    delimiter: delimiterParser,
    table: tableParser,
    paragraph: paragraphParser,
    checklist: checklistParser,
    header: headerParser,
    messageBox: messageBoxParser,
    button: buttonParser,
    attaches: attachesParser,
    blockly: blocklyParser,


}