import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserHook } from '../../actions/auth';
import { firebaseVerifyEmail, verifyAndConfirmPasswordReset } from './SocialLogin';

function PasswordReset(props) {


  const [component, setComponent] = useState("")

  const [emailVerificationMessage, setEmailVerificationMessage] = useState("Verifying your email...")
  const search = props.location.search
  const params = new URLSearchParams(search)
  const mode = params.get("mode")
  const actionCode = params.get("oobCode")
  const continueUrl = params.get("continueUrl")
  const data = {
    email: "",
    password: "",
    confirmPassword: ""
  }

  useEffect(() => {
    if (mode === "resetPassword") {
      setComponent(<ResetPassword handleInputChange={handleInputChange} handlePasswordReset={handlePasswordReset} />)
    } else if (mode === "verifyEmail") {
      setComponent(<VerifyEmail actionCode={actionCode} continueUrl={continueUrl} />)



    }
  }, [])

  function handleInputChange(e) {
    //do nothing
    const value = e.target.value
    const name = e.target.name
    data[name] = value
    console.log(data);
  }

  function handlePasswordReset() {
    verifyAndConfirmPasswordReset(data.email, data.password, actionCode, continueUrl)
  }
  return (
    <div class="columns is-centered m-3">
      {component}

    </div>
  );
}


export function ResetPassword(props) {
  return (
    <div class="box column is-half ">
      <div class="field ">
        <label class="label level-left">Email :</label>
        <div class="control">
          <input class="input" type="text" name="email" placeholder="Reset email" onChange={props.handleInputChange}></input>
        </div>
      </div>

      <div class="field ">
        <label class="label level-left">Password :</label>
        <div class="control">
          <input class="input" type="text" name="password" placeholder="New Password" onChange={props.handleInputChange}></input>
        </div>
      </div>

      <div class="field ">
        <label class="label level-left">Confirm password :</label>
        <div class="control">
          <input class="input" type="text" name="confirmPassword" placeholder="Confirm new password" onChange={props.handleInputChange}></input>
        </div>
      </div>
      <button class="button is-primary" onClick={props.handlePasswordReset}>Save</button>

    </div>
  )
}

export function VerifyEmail(props) {
  const dispatch = useDispatch()
  const [message, setMessage] = useState("Verifying your email...")
  useEffect(() => {
    firebaseVerifyEmail(props.actionCode, props.continueUrl).then(() => {

      console.log("Email verified");
      
      
      setMessage("Your email is verified successfully. Login now")
    }).catch((error) => {
      console.log(error);
      setMessage(error.message)
    })

  }, []);

  return (
    <div class="box column is-half ">
      <section class="section">
        {message}
      </section>

    </div>
  )
}

export default PasswordReset;