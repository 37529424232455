//put the value for respectedd action
//action is called in component

export function signupTab(value) {
    return {
       type: 'SIGNUP_TAB',

       payload: value
    }
 }

 export function localSigning(value) {
   return {
      type: 'LOCAL_STORAGE',
      payload: value
   }
}

export function setIsLoading(value) {
   return {
      type: 'LOADING',
      payload: value
   }
}

export function setIsLoadingError(value) {
   return {
      type: 'NET_ERROR',
      payload: value
   }
}



