import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import Message from '../bulma-components/Message';
import { Animated } from "react-animated-css";
import { CSSTransition } from 'react-transition-group';

function FieldBox({ hasAddon, isVertical, fieldName, hasHelpIcon, children, helpText, messageHead, messageBody }) {
    const [messageVisibility, setMessageVisibility] = useState(false)

    function handleHelpClick() {
        setMessageVisibility(!messageVisibility)
    }

    return (
        <CSSTransition
            in={true}
            timeout={300}
            classNames={{

                enter: 'animate__animated animate__fadeIn',

                exit: 'animate__animated animate__fadeOut',

            }}

        >
            <div class={"field md-2" + (hasAddon ? ' has-addons' : '') + (isVertical ? ' is-vertical' : ' is-horiontal')}>
                {fieldName ?
                    <div class="field-label is-normal mb-2">
                        <label class="label level-left">{fieldName}
                            {
                                hasHelpIcon ?
                                    <FontAwesomeIcon onClick={handleHelpClick} className="m-1" icon={faQuestionCircle} /> : ''
                            }

                        </label>

                    </div>
                    :
                    null
                }

                <Message
                    visible={messageVisibility}
                    messageTitle={messageHead}
                    messageBody={messageBody}
                />

                {children}

                {helpText ?
                    <p class="help is-info">{helpText}</p>
                    :
                    null
                }
            </div>
        </CSSTransition>
    );
}

export default FieldBox;