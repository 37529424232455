import axios from 'axios'
import { getLoggedInUserId, getLoggedInUserToken } from '../utils'

//const API = axios.create({ baseUrl: 'http://localhost:5000'})
const URL = process.env.REACT_APP_API_URL
//const URL = 'https://obscure-caverns-60504.herokuapp.com'

export const signIn = ({email, password}) => axios.post(URL + '/api/signin', {},{auth:{
    username: email,
    password : password
}})

export const signUp = (formdata) => axios.post(URL + '/api/signup', formdata)

export const uploadToFirebaseStorage = (formdata, config) => axios.post(URL + '/api/firebase-upload', formdata, config)

export const uploadToCloudinary = async (formdata) => await axios.post(URL + '/api/cloudinary-upload', formdata)

export const getUrlDetails = async (data) => await axios.post(URL + '/api/getUrlDetails', data)

export const getUser = async () => await axios.get(URL + '/api/getUser', {
    headers: {
        'Authorization': 'Bearer ' + getLoggedInUserToken,
        'UserId': getLoggedInUserId
    },
})

export const getGithubToekn = async (data) => await axios.post(URL + '/api/github-auth', data)

export const socialLogin = async (formdata) => await axios.post(URL + '/api/social-login', formdata)

export const updateUser = async (data) => await axios.post(URL + '/api/update-user', data)

export const updateMe = async ({ email, updateValue }) => await axios.post(URL + '/api/update-me',
    { email, updateValue },
    {
        headers: {
            'Authorization': 'Bearer ' + getLoggedInUserToken,
         
        }
    }
)

//export const createEntry = async (body) => await axios.post(URL + '/api/create-entry', body)//token and new value

export const createExtension = async (body) => await axios.post(URL + '/api/create-extension', body ,{
    headers: {
    'Authorization': 'Bearer ' + getLoggedInUserToken,
    'UserId': getLoggedInUserId
    
}})

export const updateExtension = async (body) => await axios.post(URL + '/api/update-extension', body, {
    headers: {
    'Authorization': 'Bearer ' + getLoggedInUserToken,
    'UserId': getLoggedInUserId
    
}})

export const updateExtensionLike = async (body) => await axios.post(URL + '/api/update-extension-like', body, {
    headers: {
    'Authorization': 'Bearer ' + getLoggedInUserToken,
    'UserId': getLoggedInUserId
    
}})

//export const getAllExtensions = async ({filter, sort, indexUpto}) => await axios.post(URL + '/api/get-all-extensions', {filter, sort, indexUpto});

export const getAllExtensionsForHome = async ({ searchText, typeFilter, sort }) => await axios.post(URL + "/api/get-all-extensions-for-home", { searchText, typeFilter, sort });

export const getMyExtensions = async ({ extensionIds, indexUpto }) => await axios.post(URL + '/api/get-my-extensions', {  extensionIds, indexUpto }, 
{
    headers: {
    'Authorization': 'Bearer ' + getLoggedInUserToken,
    'UserId': getLoggedInUserId
    
}}
);

export const getExtensionsByIds = async (body) => await axios.post(URL + '/api/get-extensions', body,  
{
    headers: {
    'Authorization': 'Bearer ' + getLoggedInUserToken,
    'UserId': getLoggedInUserId
    
}})

export const getExtensionBySlug = async (slug) => await axios.get(URL + '/api/get-extension-by-slug/' + slug)

export const deleteMyExtensionAPI = async ({ extensionId }) => await axios.post(URL + '/api/delete-my-extension', { extensionId }, {
    headers: {
        'Authorization': 'Bearer ' + getLoggedInUserToken,
        'UserId': getLoggedInUserId
    }
})

export const deleteAixFromFirebaseAPI = async (body) => await axios.post(URL + '/api/delete-aix-from-firebase', body)

//Razorpay
export const RazorpayApi = {

    createOder: async ({ amount, currency, reciept, notes }) => await axios.post(URL + '/api/create-razorpay-order', { amount, currency, reciept, notes })
}

