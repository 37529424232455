import { faBoxes, faCogs, faDoorOpen, faList, faPlug, faSignOutAlt, faStar, faUser, faWalking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from '../../actions/auth';

/**
 * A component to navigate to dahsbord and control user
 *
 */
function DashboardNav(props) {

    const user = useSelector(state => state.common.reduxUser);

    const dispatch = useDispatch()

    function handleSignOut(e){
        console.log("Signout clicked");
        dispatch(signOut())
    }


    return (
        <div class="dropdown is-hoverable is-right transition">
            <div class="dropdown-trigger">
                {user?.avatar ?
                <figure class="image" >
                    <img class="dashboardButton"  src={user?.avatar}></img>
                </figure>
                :
                <Skeleton circle={true} width={48} height={48}/>
}
            </div>
            <div class="dropdown-menu " id="dropdown-menu2" role="menu">
                <div class="dropdown-content">
                    <Link to="/dashboard" class="dropdown-item">
                        <span class="icon">
                            <FontAwesomeIcon icon={faBoxes}  />
                        </span>
                        <strong>Dashboard </strong>


                    </Link>
                   
                    <Link to="/dashboard" class="dropdown-item">
                        <span class="icon">
                            <FontAwesomeIcon icon={faPlug}  />
                        </span>
                        <strong> New Extension </strong>
                    </Link>

                    <Link to="/dashboard" class="dropdown-item">
                    <span class="icon">
                        <FontAwesomeIcon icon={faWalking}  />
                    </span>
                    <strong>Activities </strong>
                    </Link>

                    <Link to="dashboard/my-extensions" class="dropdown-item">
                    <span class="icon">
                        <FontAwesomeIcon icon={faList}  />
                    </span>
                    <strong>Extensions </strong>
                    </Link>

                    <Link to="/dashboard" class="dropdown-item">
                    <span class="icon">
                        <FontAwesomeIcon icon={faStar}  />
                    </span>
                    <strong>Favourites </strong>
                    </Link>

                    <Link to="#jerin" class="dropdown-item ">
                    <span class="icon">
                        <FontAwesomeIcon icon={faCogs}  />
                    </span>
                    <strong>Notifications </strong>
                    </Link>
                    <hr class="dropdown-divider"></hr>

                    <button onClick={handleSignOut} class="dropdown-item button is-primary ">
                    <span class="icon">
                        <FontAwesomeIcon icon={faSignOutAlt}  />
                    </span>
                    <strong>Sign Out</strong>
                    </button>

                </div>
            </div>
        </div>
    );
}

export default DashboardNav;