import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DashboardNav from './bulma-components/DashboardNav';
import Modal from './Modal'
import Signup from './Signup'



function NavHeader(props) {

  const [isActive, setIsActive] = useState("")
  const [modalNow, setmodalNow] = useState("")

  const isSignedInLocal = useSelector((state) => state.common.isSignedInLocal);

  function handleClick() {
    setIsActive(isActive === "is-active" ? '' : 'is-active')
  }

  const handleSignButtonClick = () => {
    setmodalNow(modalNow === "is-active" ? '' : 'is-active')
  }

  const handleModalShow = (value) => {
    setmodalNow(value)
  }

  let button;
  if (isSignedInLocal) {
    button = <DashboardNav />

  } else {
    button =
      <div>
        <button className="button is-primary" onClick={handleSignButtonClick} ><strong>Sign up</strong></button>
        <button className="button is-light" onClick={handleSignButtonClick}>Log in</button>
      </div>
  }
    return (
      <nav
        className="navbar  navbar-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="https://bulma.io">
            <img
              src="https://bulma.io/images/bulma-logo.png"
              width="112"
              height="28"
            ></img>
          </a>

          <a
            role="button"
            className="navbar-burger burger"
            onClick={handleClick}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={"navbar-menu" + isActive}
        >
          <div className="navbar-start">
            <Link to="/" className="navbar-item">
              Home
            </Link>

            <a className="navbar-item">Documentation</a>

            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">More</a>

              <div className="navbar-dropdown">
                <a className="navbar-item">About</a>
                <a className="navbar-item">Jobs</a>
                <a className="navbar-item">Contact</a>
                <hr className="navbar-divider"></hr>
                <a className="navbar-item">Report an issue</a>
              </div>
            </div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">{button}</div>
            </div>
          </div>
        </div>
        <Modal
          modalNow={modalNow}
          onClosed={handleModalShow}
          children={<Signup />}
        />
      </nav>
    )

  }




  export default NavHeader;