import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';



function Message(props) {
    const [visible, setVisibility] = useState(props.visible)
    
    useEffect(() => {
        setVisibility(props.visible)
      }, [props.visible]);
    

    console.log(visible);
    return (
      <CSSTransition
                in={visible}
                timeout={300}
                classNames={{
         
                  enter: 'animate__animated animate__fadeIn',
                 
                  exit: 'animate__animated animate__fadeOut',
               
                 }}
                unmountOnExit
                >
      <article class="message is-info">
        <div class="message-header">
          <p>{props.messageTitle}</p>
          <button onClick={()=>setVisibility( false)} class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
         {props.messageBody}
        </div>
      </article>
      </CSSTransition>
    );
}

export default Message;