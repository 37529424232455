//change value for respected action

import { AIX_VERSIONS, NEW_EXTENSION_MAJOR_DETAILS, NEW_EXTENSION_MINI_DETAILS } from "../../constants/actionTypes";

const intialStates = {
    miniDetails: {
        slug: '',
        author: '',
        type: {
            isFree: true,
            price: 0,
            currency: 'INR',
            value: "Free",
            sourceURL :""

        },
        name: '',
        description: '',
        icon: '',
        packageName: '',
        minSdkVersion: 16,
        aixVersions: [],
        rawFiles: [],
        permissions: [],
        documentation: {
            html: '',
            toc: false,
            blocks: {
                "time": 1622614181529,
                "blocks": [{
                    "type": "header",
                    "data": { "text": "Designer Properties", "level": 2 },
                    "tunes": { "alignTune": { "alignment": "left" } }
                },
                {
                    "type": "header",
                    "data": { "text": "Blocks", "level": 2 },
                    "tunes": { "alignTune": { "alignment": "left" } }
                },
                ]
            }
        },
        tags: [],
        published: false,
      


    },

    extensionDocsEditor: {
        "time": 1622614181529,
        "blocks": [
            { "type": "header", "data": { "text": "Designer Properties", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } },
            { "type": "header", "data": { "text": "Blocks", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } },
        ]
    },

    extensionInitialState: {
        slug: '',
        author: '',
        type: {
            isFree: true,
            price: 0,
            currency: 'INR',
            value: "Free",
            sourceURL :""
        },
        name: '',
        description: '',
        icon: '',
        packageName: '',
        minSdkVersion: 16,
        aixVersions: [],
        rawFiles: [],
        permissions: [],
        documentation: {
            html: '',
            toc: false,
            blocks: {
                "time": 1622614181529,
                "blocks": [
                    { "type": "header", "data": { "text": "Designer Properties", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } },
                    { "type": "header", "data": { "text": "Blocks", "level": 2 }, "tunes": { "alignTune": { "alignment": "left" } } },
                ]
            }
        },
        tags: [],
        published: false,
        likes: [],
    },

}
export function extension(state = intialStates, action) {
    switch (action.type) {

        case AIX_VERSIONS:
            return {
                ...state,
                aixVersions: action.payload
            };
        case NEW_EXTENSION_MINI_DETAILS:
            return {
                ...state,
                miniDetails: action.payload
            };

        case NEW_EXTENSION_MAJOR_DETAILS:
            return {
                ...state,
                majorDetails: action.payload
            };
        case "EXTENSION_INITIAL_STATE":
            return {
                ...state,
                extensionInitialState: action.payload
            };
        case "EXTENSION_DOCUMENTATION_EDITOR":
            return {
                ...state,
                extensionDocsEditor: action.payload
            }


        default:
            return state;
    }
};